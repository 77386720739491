<template>
  <div class="hotel-information">
    <h3 class="sub-title">For Oral/Poster</h3>
    <div class="text-center">
      <a class="btn-submit"  @click="handleClick()">Click here to submit your abstract <i class="iconfont icon-youjiantou"></i></a>
    </div>

    <h4 class="title">Important Dates</h4>
    <div class="info-date">
      <div class="card-statistic lt">
        <h4 class="card-title">Abstract Submission Deadline Extended To </h4>
        <h2 >March 15(Fri),2024</h2>
        <h4 class="text-bold">
          <span :class="{'show':ltEnd}">END</span>
        </h4>
        <div class="progress-box">
          <div class="progress-bar" :style="ltEnd?'':'width:'+ltWidth"></div>
        </div>
        <i class="iconfont icon-calendar1"></i>
      </div>
      <div class="card-statistic rt">
        <h4 class="card-title">Abstract Acceptance Notice</h4>
        <h2 >April 4(Thu),2024</h2>
        <h4 class="text-bold">
          <span :class="{'show':rtEnd}">END</span>
        </h4>
        <div class="progress-box">
          <div class="progress-bar" :style="rtEnd?'':'width:'+rtWidth"></div>
        </div>
        <i class="iconfont icon-calendar1"></i>
      </div>
      
    </div>

    <h4 class="title">Topics</h4>
    <div class="content">
      <ul class="disc">
        <li>Adult Cardiac</li>
        <li>Thoracic</li>
        <li>Pediatric</li>
        <li>Others</li>
      </ul>
    </div>

    <h4 class="title">Abstract Submission Process</h4>
    <div class="content">
      <ul class="timeline timeline-left">
        <li class="timeline-item">
          <div class="timeline-badge"><span>1</span></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <span class="text-info">Step 1. Sign-up and Log-in</span>
            </div>
            <div class="timeline-body">
              <p>Sign up and log in to this website. If you are already signed-up on this website, you can use the account for your abstract submission.</p>
            </div>
          </div>
        </li>
        <li class="timeline-item">
          <div class="timeline-badge"><span>2</span></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
                <span class="text-info">Step 2. Submission and Confirmation</span>
            </div>
            <div class="timeline-body">
              <p>After uploading the required abstract contents, a confirmation letter will be sent to the presenting author and corresponding author via email.</p>
            </div>
          </div>
        </li>
        <li class="timeline-item">
          <div class="timeline-badge"><span>3</span></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <span class="text-info">Step 3. Review &amp; Modification</span>
            </div>
            <div class="timeline-body">
              <p>To review or modify your submitted abstract(s), visit 'My Page'.</p>
            </div>
          </div>
        </li>
        <li class="timeline-item">
          <div class="timeline-badge"><span>4</span></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
              <span class="text-info">Step 4. Acceptance Notice</span>
            </div>
            <div class="timeline-body">
              <p>An acceptance notice will be sent to the presenting author and corresponding author on </p>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <h4 class="title">Abstract Submission Guideline</h4>
    <div class="content">
      <ul class="disc">
        <li>Submission Method: Online submission only</li>
        <li>Category: Oral Presentation / Panel Poster / Surgical Video</li>
        <li>Abstract Format: Provided Power Point (PPT) Abstract format</li>
        <li style="list-style-type:none;">
          <mark>
            <span class="text-main">* Abstract(s) must be written in the provided PPT Abstract format. Please download and use the PPT Abstract template.</span>
            </mark>
          <a class="btn" href="https://drvoicedev.oss-cn-beijing.aliyuncs.com/drvoice/ASCVTS%202024/ASCVTS%202024_Abstract%20PPT%20Template%20.pptx"><a-icon type="download" /> PPT Abstract Template</a>
        </li>
        <li>Flow: Objective/Background, Method(s), Result(s), Conclusion</li>
        <li style="list-style-type:none;">
          <span class="text-main">* Surgical Video Abstract: PPT Abstract including 1-2 key photos of the surgical video; do NOT include the actual video in the abstract.</span>
        </li>
        <li>Language: <span class="text-main">English</span></li>
        <li>Font Type &amp; Size: Arial / minimum 16pt</li>
        <li>Length: Maximum 6 slides</li>
        <li>Submission Format: <mark><span class="text-main">Your PPT Abstract file must be converted into a PDF file format for submission.</span></mark></li>
        <li>Modification: Available on ‘My Page’ until the abstract submission deadline</li>
        <li>Withdrawal: Written request via email (<a href="mailto:ASCVTS2024WUHAN@hotmail.com">ASCVTS2024WUHAN@hotmail.com</a>) required</li>
      </ul>
    </div>

    <div class="text-center">
      <a class="btn-submit" @click="handleClick()">Click here to submit your abstract <i class="iconfont icon-youjiantou"></i></a>
    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {
      ltEnd:false,
      rtEnd:false,
      ltWidth:'',
      rtWidth:''
    }
  },
  created(){
    // 计算结束时间
    const nowDate = new Date()
    const startTime = '2023-12-20'
    const endlt = '2024-3-15'
    const endrt = '2024-4-4'

    let myDate_1 = Date.parse(nowDate)
    let myDate_2 = Date.parse(startTime)
    let myDate_3 = Date.parse(endlt)
    let myDate_4 = Date.parse(endrt)

    if ( myDate_1 > myDate_3) {
      // 左边end
      this.ltEnd = true
    } else {
      // 计算左进度条长度
      let res = (myDate_1-myDate_2)/(myDate_3 - myDate_2)
      this.ltWidth = Math.round(res * 100) + '%'
    }
    if ( myDate_1 > myDate_4) {
      // 右边end
      this.rtEnd = true
    } else {
      // 计算右进度条长度
      let res = (myDate_1-myDate_2)/(myDate_4 - myDate_2)
      this.rtWidth = Math.round(res * 100) + '%'
    }
  },
  methods:{
    handleClick(){
      window.location.href = 'https://ascvts2024.sciconf.cn/en/user/login/21452'
      // sessionStorage.setItem('abstract_submit', true)
      // this.$router.push({name:'myPage'})
    }
  }
}
</script>

<style lang="less" scoped>
.hotel-information {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  .text-center {
    padding: 20px;
    .btn-submit {
      font-family: "Akrobat Regular";
      padding: 8px 16px;
      font-size: 20px;
      border-radius: 6px;
      background-color: #dc3545;
      color: #fff;
      .iconfont {
        font-size: 20px;
      }
      &:hover {
        background-color: #c82333;
      }
      @media (max-width:575px) {
        font-size: 18px;
        .iconfont {
          font-size: 18px;
        }
      }
    }
  }
  p {
    // font-family: "PublicSans Regular";
    // font-family: "Akrobat Regular";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    line-height: 26px;
    font-size: 16px;
    text-align: justify;
  }
  .title {
    font-family: "Akrobat Black";
    font-weight: 700;
    position: relative;
    margin: 15px 0 15px;
    padding-left: 15px;
    border-left: 3px solid #19317e;
    font-size: 24px;
    line-height: 28px;
  }
  .content {
    font-family: "PublicSans Regular";
    // font-family: "Akrobat Regular";
    font-size: 16px;
    padding-bottom: 20px;
    .disc {
      li {
        // padding-left: 20px;
        margin-left: 20px;
        line-height: 28px;
        position: relative;
        color: #333;
        list-style: disc;
        list-style-position: outside;
        // &::after {
        //   content:'';
        //   position: absolute;
        //   width: 6px;
        //   height: 6px;
        //   border-radius: 50%;
        //   background-color: #333;
        //   left: 2px;
        //   top: 11px;
        // }
        .text-main {
          color: #146992;
          font-family: "PublicSans Black";
        }
        .btn {
          display: inline-block;
          border-radius: 4px;
          font-size: 14px;
          line-height: 21px;
          border: 1px solid #007bff;
          color: #007bff;
          padding: 4px 8px;
          cursor: pointer;
          transition: .3s;
          &:hover {
            background-color: #007bff;
            color: #fff;
          }
          /deep/ .anticon {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .timeline {
    position: relative;
    padding-top: 20px;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 30px;
      width: 3px;
      margin-left: -1.5px;
      background-color: #e9ecef;
    }
    .timeline-item {
      position: relative;
      overflow: hidden;
      margin-bottom: 20px;
      .timeline-badge {
        left: 30px;
        top: 9px;
        z-index: 10;
        position: absolute;
        width: 50px;
        height: 50px;
        margin-left: -25px;
        border-radius: 50% 50% 50% 50%;
        text-align: center;
        font-size: 1.4em;
        line-height: 50px;
        color: #fff;
        overflow: hidden;
        background-color: #1169ac;
      }
      .timeline-panel {
        float: right;
        width: calc(100% - 80px);
        position: relative;
        padding: 10px 20px;
        border: 1px solid #e9ecef;
        border-radius: 4px;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
        &::before {
          content: "";
          right: auto;
          left: -16px;
          display: inline-block;
          position: absolute;
          top: 26px;
          border-color: transparent #e9ecef transparent transparent;
          border-style: solid;
          border-width: 8px 8px 8px 8px;
        }
        &::after {
          content: "";
          right: auto;
          left: -15px;
          display: inline-block;
          position: absolute;
          top: 26px;
          border-color: transparent #fff transparent transparent;;
          border-style: solid;
          border-width: 8px 8px 8px 8px;
        }
        .timeline-heading {
          font-size: 18px;
          color: #1169ac;
          font-family: "PublicSans bold";
        }
      }
    }
  }
  .info-date {
    display: flex;
    // justify-content: space-between;
    .card-statistic {
      position: relative;
      font-family: "PublicSans Regular";
      font-size: 16px;
      padding: 16px;
      border-radius: 6px;
      color: #fff;
      width: calc( 50% - 10px );
      max-width: 450px;
      &.lt {
        background: #33599e;
        margin-right: 20px;
      }
      &.rt {
        background-color: #1abb9c;
      }
      .card-title {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
      }
      h2 {
        color: #fff;
        font-size: 24px;
        font-weight: bold;
      }
      .text-bold {
        text-align: right;
        span {
          color: #fff;
          font-weight: bold;
          visibility: hidden;
          &.show {
            visibility: visible;
          }
        }
      }
      .progress-box {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
        .progress-bar {
          background-color: #d9534f !important;
          background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
          background-size: 1rem 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;
          color: #fff;
          text-align: center;
          white-space: nowrap;
          transition: width .6s ease;
          line-height: 0;
          font-size: .75rem;
          height: 20px;
          border-radius: 4px;
        }
      }
      .iconfont {
        position: absolute;
        top: 8px;
        right: 16px;
        font-size: 40px;
        color: rgba(255, 255, 255, 0.7);
        font-weight: bold;
      }
    }
    @media (max-width:992px) {
      display: block;
      .card-statistic {
        width: 100%;
        max-width: 100%;
        &.lt {
          background: #33599e;
          margin: 0 0 20px;
        }
      }
    }
  }
}
</style>
